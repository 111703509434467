

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">

    <el-card class="SearchBox">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="指定活动">
          <el-select v-model="searchForm.activeId" placeholder="请选择活动">
            <el-option v-for="activ in activeDrops" :key="activ.value" :value="activ.value" :label="activ.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转赠状态">
          <el-select v-model="searchForm.state" placeholder="请选择订单状态">
            <el-option v-for="item in shareStateDrops" :key="item.value" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转赠用户昵称">
          <el-input placeholder="请输入用户昵称" v-model="searchForm.appusername" clearable />
        </el-form-item>
        <el-form-item label="接收用户昵称">
          <el-input placeholder="请输入订单号" v-model="searchForm.receiverName" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dataBinding">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never" class="border-none">
      <el-table :data="tableData" stripe fit>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item v-for="item in props.row.items" :key="item.productId" :label="item.productName">
                <el-input v-model="item.productCount" disabled></el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="id" width="100" label="转赠用户">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="right">
              <h3>转赠酒水 <el-tag :type="scope.row.stateTagType" disable-transitions>{{scope.row.stateName}}</el-tag>
              </h3>
              <p v-for="item in scope.row.items" :key="item.productId">
                {{item.productName}}：{{item.productCount}}
              </p>
              <div slot="reference">
                <el-avatar size="large" :src="scope.row.appUserAvatar"></el-avatar>
                <div>{{scope.row.appUserName}}</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="酒水总数" width="100" prop="">
          <template slot-scope="scope">
            <span>
              {{talCheckCount(scope.row.items) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="state" width="100" label="领取状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.stateTagType" disable-transitions>{{scope.row.stateName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="" width="100" label="领取用户">
          <template slot-scope="scope">
            <div v-if="scope.row.receiverId>0">
              <el-avatar size="large" :src="scope.row.receiverAvatar"></el-avatar>
              <div>{{scope.row.receiverName}}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="createTimeStr" label="转赠时间" />
        <el-table-column prop="receiveTimeStr" label="领取时间" />

      </el-table>

      <div class="margin-t16 text-right">
        <el-pagination background layout="prev, pager,next" :total="totalCount" :current-page="searchForm.pageIndex"
          :page-size="searchForm.pageSize" @current-change="handleCurrentChange">
        </el-pagination>
      </div>

    </el-card>
  </div>
</template>
<script>
import { getShareList } from '@/service/statistics.js';
import { activities, scenes } from '@/service/dropdown.js';

export default {
  data() {
    return {
      loading: false,
      searchOpen: false,
      totalCount: 0,
      activeDrops: [],
      activeSceneDrops: [{ label: '--全部--', value: 0 }],
      shareStateDrops: [
        { label: '--全部--', value: -1 },
        { label: '待领取', value: 0 },
        { label: '已领取', value: 1 },
        { label: '已撤回', value: 7 }
      ],
      searchForm: {
        pageIndex: 1,
        pageSize: 20,
        activeId: 0,
        appusername: '',
        receiverName: '',
        state: -1,
        beginDate: '',
        endDate: '',
      },
      tableData: []
    };
  },
  async created() {
    await this.getActivitiesDropdown();
    await this.dataBinding();
    await this.getSceneDropdown();
  },
  computed: {
    talCheckCount(list) {
      return function (list) {
        let count = 0;
        list.forEach(elem => {
          count += elem.productCount;
        })
        return count;
      }
    }
  },
  methods: {
    async getSceneDropdown() {
      return new Promise((resolve, reject) => {
        scenes().then(res => {
          if (res.code == 0) {
            this.activeSceneDrops = [{ label: '--全部--', value: 0 }];
            res.data.forEach(element => {
              this.activeSceneDrops.push(element);
            });
            resolve();
          }
        })
      });
    },
    //获取活动下拉数据
    async getActivitiesDropdown() {
      return new Promise((resolve, reject) => {
        activities().then(async res => {
          if (res.code == 0) {
            this.activeDrops = res.data;
            this.searchForm.activeId = res.data[0].value;

            resolve();
          }
        });
      })
    },
    //加载页面数据
    async dataBinding() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        getShareList(this.searchForm).then(res => {
          this.totalCount = res.data.totalCount;
          this.tableData = res.data.data;
          this.loading = false;
          resolve();
        });
      })
    },
    //页码切换
    handleCurrentChange(val) {
      if (this.searchForm.pageIndex != val) {
        this.searchForm.pageIndex = val;
        this.dataBinding();
      }
    }
  }
};
</script>
<style scoped>
.Middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.SearchBox {
  margin-bottom: 30px;
}
</style>